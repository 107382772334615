@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "@core/scss/angular/libs/select.component.scss";
@import '~ngx-toastr/toastr.css';
@import "@core/scss/angular/libs/datatables.component.scss";
@import "@core/scss/angular/libs/swiper.component.scss";
@import '@core/scss/angular/libs/drag-drop.component.scss';


@import '@core/scss/base/pages/app-ecommerce.scss';
@import '@core/scss/angular/libs/form-wizard.component.scss';
@import '@core/scss/base/plugins/forms/form-validation.scss';
@import '@core/scss/angular/libs/ratings.component.scss';
@import '@core/scss/base/pages/app-user.scss';



.datatable-scroll {
  width: auto !important;
}

.floating-button {
  position: fixed;
  bottom: 5%;
  right: 50px;
  z-index: 1031;
  .btn {
    box-shadow: 0 1px 20px 1px $primary !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}


